import React, { useState } from "react"
import {
  Grid,
  Typography,
  Radio,
  RadioGroup,
  FormGroup,
  FormControl,
  FormControlLabel,
  FormLabel,
  TextField,
  Checkbox,
  Hidden,
  Box,
  Button,
} from "@material-ui/core"
import { useSelector } from "react-redux"
import zhutravelTheme from "../components/Layout/zhutravelTheme"
import CustomTourContainer from "../components/CustomTour/CustomTourContainer"
import LocalizedLink from "../components/LocalizedLink"
import {
  makeStyles,
  createMuiTheme,
  ThemeProvider,
} from "@material-ui/core/styles"
import { SEO } from "../components/SEO"

const mapTheme = createMuiTheme(zhutravelTheme, {
  palette: {
    secondary: {
      main: "#FF9486",
      contrastText: "#010204",
      dark: "#ff715f",
    },
  },
  overrides: {
    MuiTypography: {
      overline: {
        display: "block",
        marginTop: zhutravelTheme.spacing(4),
        fontFamily: "MabryProLight",
      },
    },
    MuiCardContent: {
      root: {
        padding: zhutravelTheme.spacing(3),
      },
    },
    MuiFormLabel: {
      root: {
        fontSize: "0.875rem",
      },
    },
    MuiFormControl: {
      root: {
        marginTop: zhutravelTheme.spacing(4),
      },
    },
    MuiFormControlLabel: {
      root: {
        justifyContent: "space-between",
        color: "secondary",
        borderBottom: "1px solid rgba(1,2,4,0.1)",
      },
      labelPlacementStart: {
        marginLeft: 0,
        marginRight: 0,
      },
      label: {
        fontFamily: "MabryProRegular",
      },
    },
    MuiRadio: {
      root: {
        padding: zhutravelTheme.spacing(1),
      },
    },
    MuiInputLabel: {
      shrink: {
        transform: "translate(0, 1.5px)",
      },
    },
  },
  props: {
    MuiFormControl: {
      fullWidth: true,
    },
    MuiFormControlLabel: {
      labelPlacement: "start",
    },
    MuiRadio: {
      size: "small",
    },
    MuiCheckbox: {
      size: "small",
    },
  },
})

const useStyles = makeStyles(theme => ({
  cardStyle: {
    margin: theme.spacing(2),
    width: "100%",
  },
  logo: {
    fontFamily: "MabryProBold",
    fontSize: "1.5rem",
    fontWeight: "bold",
    padding: 0,
    lineHeight: "24px",
  },
  pgNumStyle: {
    fontSize: "2rem",
    lineHeight: "32px",
  },
}))

const CreateCustom = ({ pageContext, location }) => {
  const reqsData = location?.state?.reqsData ? location.state.reqsData : null
  const translations = useSelector(
    state => state?.locale?.localeObj?.pageData?.mapScreen
  )
  const [flightBooked, setFlightBooked] = useState(
    reqsData ? reqsData.flightBooked : ""
  )
  const [transportNeeded, setTransportNeeded] = useState(
    reqsData ? reqsData.transportNeeded : ""
  )
  const [itinerary, setItinerary] = useState(reqsData ? reqsData.itinerary : "")
  const [accommodation, setAccommodation] = useState(
    reqsData
      ? reqsData.accommodation
      : {
          threeStar: false,
          fourStar: false,
          fiveStar: false,
          budget: false,
          apartments: false,
        }
  )
  const [meals, setMeals] = useState(reqsData ? reqsData.meals : "")
  const [guide, setGuide] = useState(reqsData ? reqsData.guide : "")
  const [siteseeing, setSiteseeing] = useState(
    reqsData ? reqsData.siteseeing : ""
  )
  const [insurance, setInsurance] = useState(reqsData ? reqsData.insurance : "")
  const [requests, setRequests] = useState(reqsData ? reqsData.requests : "")

  const questionsList = [
    {
      title: translations?.flightsBooked,
      name: "flightBooked",
      value: flightBooked,
      setFunc: setFlightBooked,
      options: [
        { value: "yes", label: translations?.yes },
        { value: "no", label: translations?.no },
      ],
    },
    {
      title: translations?.transportationRequired,
      name: "transport",
      value: transportNeeded,
      setFunc: setTransportNeeded,
      options: [
        { value: "yes", label: translations?.yes },
        { value: "no", label: translations?.no },
      ],
    },
    {
      title: translations?.itineraryRegime,
      name: "itinerary",
      value: itinerary,
      setFunc: setItinerary,
      options: [
        { value: "Relaxed", label: translations?.relaxed },
        { value: "Balanced", label: translations?.balanced },
        { value: "Tight", label: translations?.tight },
      ],
    },
    {
      title: translations?.accommodation,
      name: "accommodation",
      value: accommodation,
      setFunc: setAccommodation,
      checkboxes: true,
      options: [
        { value: "threeStar", label: translations?.hotel3Stars },
        { value: "fourStar", label: translations?.hotel4Stars },
        { value: "fiveStar", label: translations?.hotel5Stars },
        { value: "budget", label: translations?.budgetFriendly },
        { value: "apartments", label: translations?.apartments },
      ],
    },
    {
      title: translations?.meals,
      name: "meals",
      value: meals,
      setFunc: setMeals,
      options: [
        { value: "Cheap", label: translations?.cheap },
        { value: "Balanced", label: translations?.balancedMeal },
        { value: "Expensive", label: translations?.expensive },
      ],
    },
    {
      title: translations?.tourGuide,
      name: "guide",
      value: guide,
      setFunc: setGuide,
      options: [
        { value: "yes", label: translations?.yes },
        { value: "no", label: translations?.no },
      ],
    },
    {
      title: translations?.sightseeing,
      name: "siteseeing",
      value: siteseeing,
      setFunc: setSiteseeing,
      options: [
        { value: "yes", label: translations?.yes },
        { value: "no", label: translations?.no },
      ],
    },
    {
      title: translations?.travelInsurance,
      name: "insurance",
      value: insurance,
      setFunc: setInsurance,
      options: [
        { value: "yes", label: translations?.yes },
        { value: "no", label: translations?.no },
      ],
    },
  ]

  const CustomRadioGroup = ({ question }) => {
    const { title, name, value, setFunc, options, checkboxes } = question

    return (
      <FormControl component="fieldset">
        <FormLabel component="legend">{title}</FormLabel>
        <RadioGroup
          name={name}
          value={value}
          onChange={event => setFunc(event.target.value)}
        >
          {options.map(({ value, label }) => (
            <FormControlLabel
              key={label}
              value={value}
              control={checkboxes ? <Checkbox /> : <Radio />}
              label={label}
            />
          ))}
        </RadioGroup>
      </FormControl>
    )
  }

  const classes = useStyles()

  return (
    <CustomTourContainer
      pageContext={pageContext}
      location={location}
      pgNum={2}
      buttonProps={{
        to: "/CustomTour",
        state: {
          itineraryData: location?.state?.itineraryData,
          reqsData: {
            flightBooked,
            transportNeeded,
            itinerary,
            accommodation,
            meals,
            guide,
            siteseeing,
            insurance,
            requests,
          },
        },
        back: "/MapScreen",
      }}
    >
      <SEO/>
      <ThemeProvider theme={mapTheme}>
        <Hidden smDown>
          <Grid container spacing={3}>
            <Grid item xs={1} />
            <Grid item x={4}>
              <Typography variant="h6">{translations?.tourDetails}</Typography>
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid item xs={1} />
            <Grid item xs={4}>
              <CustomRadioGroup question={questionsList[0]} />
              <CustomRadioGroup question={questionsList[1]} />
              <CustomRadioGroup question={questionsList[2]} />
              <FormControl component="fieldset">
                <FormLabel component="legend">{translations?.accommodation}</FormLabel>
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={accommodation.threeStar}
                        onChange={event => {
                          setAccommodation({
                            ...accommodation,
                            [event.target.name]: event.target.checked,
                          })
                        }}
                        name="threeStar"
                      />
                    }
                    label={translations?.hotel3Stars}
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={accommodation.fourStar}
                        onChange={event => {
                          setAccommodation({
                            ...accommodation,
                            [event.target.name]: event.target.checked,
                          })
                        }}
                        name="fourStar"
                      />
                    }
                    label={translations?.hotel4Stars}
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={accommodation.fiveStar}
                        onChange={event => {
                          setAccommodation({
                            ...accommodation,
                            [event.target.name]: event.target.checked,
                          })
                        }}
                        name="fiveStar"
                      />
                    }
                    label={translations?.hotel5Stars}
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={accommodation.budget}
                        onChange={event => {
                          setAccommodation({
                            ...accommodation,
                            [event.target.name]: event.target.checked,
                          })
                        }}
                        name="budget"
                      />
                    }
                    label={translations?.budgetFriendly}
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={accommodation.apartments}
                        onChange={event => {
                          setAccommodation({
                            ...accommodation,
                            [event.target.name]: event.target.checked,
                          })
                        }}
                        name="apartments"
                      />
                    }
                    label={translations?.apartments}
                  />
                </FormGroup>
              </FormControl>
            </Grid>
            <Grid item xs={1} />
            <Grid item xs={4}>
              <CustomRadioGroup question={questionsList[4]} />
              <CustomRadioGroup question={questionsList[5]} />
              <CustomRadioGroup question={questionsList[6]} />
              <CustomRadioGroup question={questionsList[7]} />

              <TextField
                placeholder={translations?.typeRequest}
                fullWidth
                label={translations?.specialRequests}
                InputLabelProps={{
                  shrink: true,
                }}
                value={requests}
                onChange={event => {
                  console.log(reqsData)
                  setRequests(event.target.value)
                }}
              />
            </Grid>
          </Grid>
        </Hidden>
        <Hidden mdUp>
          <Box display="flex" justifyContent="space-between" mb={4}>
            <Button
              component={LocalizedLink}
              to="/"
              disableRipple
              className={classes.logo}
            >
              {translations?.zhutravel}
            </Button>
            <Typography className={classes.pgNumStyle}>{2}&frasl;3</Typography>
          </Box>
          <Grid container spacing={3}>
            <Grid item x={12}>
              <Typography variant="h6">{translations?.tourDetails}</Typography>
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <CustomRadioGroup question={questionsList[0]} />
              <CustomRadioGroup question={questionsList[1]} />
              <CustomRadioGroup question={questionsList[2]} />
              <FormControl component="fieldset">
                <FormLabel component="legend">{translations?.accommodation}</FormLabel>
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={accommodation.threeStar}
                        onChange={event => {
                          setAccommodation({
                            ...accommodation,
                            [event.target.name]: event.target.checked,
                          })
                        }}
                        name="threeStar"
                      />
                    }
                    label={translations?.hotel3Stars}
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={accommodation.fourStar}
                        onChange={event => {
                          setAccommodation({
                            ...accommodation,
                            [event.target.name]: event.target.checked,
                          })
                        }}
                        name="fourStar"
                      />
                    }
                    label={translations?.hotel4Stars}
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={accommodation.fiveStar}
                        onChange={event => {
                          setAccommodation({
                            ...accommodation,
                            [event.target.name]: event.target.checked,
                          })
                        }}
                        name="fiveStar"
                      />
                    }
                    label={translations?.hotel5Stars}
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={accommodation.budget}
                        onChange={event => {
                          setAccommodation({
                            ...accommodation,
                            [event.target.name]: event.target.checked,
                          })
                        }}
                        name="budget"
                      />
                    }
                    label={translations?.budgetFriendly}
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={accommodation.apartments}
                        onChange={event => {
                          setAccommodation({
                            ...accommodation,
                            [event.target.name]: event.target.checked,
                          })
                        }}
                        name="apartments"
                      />
                    }
                    label={translations?.apartments}
                  />
                </FormGroup>
              </FormControl>
              <CustomRadioGroup question={questionsList[4]} />
              <CustomRadioGroup question={questionsList[5]} />
              <CustomRadioGroup question={questionsList[6]} />
              <CustomRadioGroup question={questionsList[7]} />

              <TextField
                placeholder={translations?.typeRequest}
                fullWidth
                label={translations?.specialRequests}
                InputLabelProps={{
                  shrink: true,
                }}
                value={requests}
                onChange={event => {
                  console.log(reqsData)
                  setRequests(event.target.value)
                }}
              />
            </Grid>
          </Grid>
        </Hidden>
      </ThemeProvider>
    </CustomTourContainer>
  )
}

export default CreateCustom
